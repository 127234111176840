<template>
  <div
    class="tw-flex tw-flex-col tw-gap-5 tw-px-4 tw-py-8 lg:tw-gap-7 lg:tw-px-7 lg:tw-py-12"
    :style="`background-color: var(--c-${vars.backgroundColor}); border-radius: var(--rounded);`"
  >
    <h3>{{ vars.titleText }}</h3>
    <library-image :src="vars.personImage" :w-ratio="1" :h-ratio="1.4" />

    <div class="tw-flex tw-items-center tw-gap-1">
      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M13.34 10.2533C12.52 10.2533 11.7267 10.12 10.9867 9.88C10.8707 9.8407 10.7461 9.83487 10.627 9.86317C10.5079 9.89147 10.3992 9.95275 10.3133 10.04L9.26667 11.3533C7.38 10.4533 5.61333 8.75333 4.67333 6.8L5.97333 5.69333C6.15333 5.50667 6.20667 5.24667 6.13333 5.01333C5.88667 4.27333 5.76 3.48 5.76 2.66C5.76 2.3 5.46 2 5.1 2H2.79333C2.43333 2 2 2.16 2 2.66C2 8.85333 7.15333 14 13.34 14C13.8133 14 14 13.58 14 13.2133V10.9133C14 10.5533 13.7 10.2533 13.34 10.2533Z"
          fill="var(--c-border)"
        />
      </svg>
      <nuxt-link :to="`tel: ${vars.phoneUrl}`" class="n-link tw-max-w-full">{{ vars.phoneUrl }}</nuxt-link>
    </div>

    <div class="tw-flex tw-items-center tw-gap-1">
      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M2.66671 13.3334C2.30004 13.3334 1.98604 13.2027 1.72471 12.9414C1.46338 12.68 1.33293 12.3662 1.33338 12V4.00002C1.33338 3.63335 1.46404 3.31935 1.72538 3.05802C1.98671 2.79669 2.30049 2.66624 2.66671 2.66669H13.3334C13.7 2.66669 14.014 2.79735 14.2754 3.05869C14.5367 3.32002 14.6672 3.6338 14.6667 4.00002V12C14.6667 12.3667 14.536 12.6807 14.2747 12.942C14.0134 13.2034 13.6996 13.3338 13.3334 13.3334H2.66671ZM8.00004 8.66669L13.3334 5.33335V4.00002L8.00004 7.33335L2.66671 4.00002V5.33335L8.00004 8.66669Z"
          fill="var(--c-border)"
        />
      </svg>
      <nuxt-link :to="`mailto:${vars.emailUrl}`" class="n-link tw-break-all">{{ vars.emailUrl }}</nuxt-link>
    </div>

    <p v-for="({ description }, index) in descriptions" :key="`meetcard-descriptions-${index}`">
      {{ description }}
    </p>

    <nuxt-link
      v-for="(item, index) in buttons"
      :key="`meetcard-buttons-${index}`"
      :to="item.link"
      :class="`tw-max-w-fit n-${item.type}`"
    >
      {{ item.label }}
    </nuxt-link>
  </div>
</template>

<script lang="ts">
import { ComponentMixin } from '~/mixins/component.mixin';

export default defineNuxtComponent({
  name: 'MeetCard',

  mixins: [ComponentMixin],

  computed: {
    descriptions() {
      return this.groupedVariables.descriptions;
    },

    buttons() {
      return this.groupedVariables.buttons;
    },
  },
});
</script>

<style scoped>
.n-link {
  color: var(--c-border);
}
</style>
