<template>
  <div class="tw-flex tw-w-full tw-flex-col tw-gap-7 tw-px-7 tw-py-12" :style="computedStyles">
    <h3>{{ vars.titleText }}</h3>

    <p>{{ vars.descriptionText }}</p>

    <div class="tw-flex tw-max-w-fit tw-flex-col tw-gap-7">
      <nuxt-link
        v-for="(button, index) in buttons"
        :key="`info-card-button-${index}`"
        :to="button.link"
        :class="`n-${button.type}`"
      >
        {{ button.label }}
      </nuxt-link>
    </div>
  </div>
</template>

<script lang="ts">
import { ComponentMixin } from '~/mixins/component.mixin';

export default defineNuxtComponent({
  name: 'InfoCard',

  mixins: [ComponentMixin],

  computed: {
    buttons() {
      return this.groupedVariables.buttons;
    },

    computedStyles() {
      const borderBottomColor = this.vars.borderBottomColor || 'border';
      const borderTopColor = this.vars.borderTopColor || 'border';

      return `
        background-color: var(--c-${this.vars.backgroundColor});
        border-bottom: 1px solid var(--c-${borderBottomColor});
        border-top: 1px solid var(--c-${borderTopColor});
      `;
    },
  },
});
</script>
