<template>
  <section
    v-if="sidebarComponents.length === 0"
    class="n-section-primary"
    :style="`background-color: var(--c-${vars.backgroundColor})`"
  >
    <div class="tw-grid tw-w-full tw-grid-cols-12 tw-grid-rows-1 tw-items-center tw-space-y-5 lg:tw-space-y-0">
      <LibraryPopupsVideoPopup v-if="vars.photoImage" :videoUrl="vars.videoUrl" v-model:is-visible="isVisible" />
      <div
        :class="`${vars.isReversed && 'tw-order-3'} tw-relative tw-col-span-full lg:tw-col-span-6 xl:tw-col-span-5`"
        @click="isVisible = true"
      >
        <LibraryImage v-if="vars.photoImage" :src="vars.photoImage" :h-ratio="430" :w-ratio="645" />

        <library-video-embed
          v-if="!vars.photoImage && vars.videoUrl"
          :video-url="vars.videoUrl"
          title="Video Popup"
          :library-iframe="true"
          :is-popup="vars.isPopupBool"
          :h-ratio="430"
          :w-ratio="645"
        />

        <div
          v-if="vars.videoUrl && vars.isPopupBool"
          class="tw-absolute tw-left-1/2 tw-top-1/2 -tw-translate-x-1/2 -tw-translate-y-1/2"
        >
          <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M32 4C26.4621 4 21.0486 5.64217 16.444 8.71885C11.8395 11.7955 8.25064 16.1685 6.13139 21.2849C4.01213 26.4012 3.45764 32.0311 4.53802 37.4625C5.61841 42.894 8.28515 47.8831 12.201 51.799C16.1169 55.7149 21.106 58.3816 26.5375 59.462C31.969 60.5424 37.5988 59.9879 42.7151 57.8686C47.8315 55.7494 52.2045 52.1605 55.2812 47.556C58.3578 42.9514 60 37.5379 60 32C60 24.5739 57.05 17.452 51.799 12.201C46.548 6.94999 39.4261 4 32 4ZM46.894 33.79L22.894 45.79C22.589 45.9424 22.2501 46.0143 21.9095 45.9989C21.5689 45.9835 21.2379 45.8812 20.948 45.7019C20.658 45.5225 20.4187 45.272 20.2528 44.9741C20.0869 44.6763 19.9999 44.341 20 44V20C20.0002 19.6592 20.0875 19.3241 20.2535 19.0265C20.4196 18.729 20.6589 18.4787 20.9488 18.2996C21.2387 18.1205 21.5696 18.0184 21.91 18.0031C22.2505 17.9878 22.5892 18.0597 22.894 18.212L46.894 30.212C47.2258 30.3783 47.5047 30.6336 47.6997 30.9494C47.8946 31.2651 47.9979 31.6289 47.9979 32C47.9979 32.3711 47.8946 32.7349 47.6997 33.0507C47.5047 33.3664 47.2258 33.6217 46.894 33.788"
              fill="white"
            />
          </svg>
        </div>
      </div>
      <div :class="`${vars.isReversed && 'tw-order-2'} tw-col-span-1`"></div>
      <div class="tw-col-span-full lg:tw-col-span-5">
        <div v-html="vars.titleHtml" class="tw-flex tw-flex-wrap tw-gap-2"></div>
        <div class="tw-space-y-2">
          <p
            v-for="(description, index) in descriptions"
            :key="`contentbox-description-${index}`"
            style="color: var(--c-text_black)"
          >
            {{ description.description }}
          </p>
        </div>
        <div class="tw-mt-8 tw-flex tw-flex-wrap tw-gap-2">
          <nuxt-link
            v-for="(button, index) in buttons"
            :key="`contentbox-button-${index}`"
            :to="button.link"
            :class="`n-${button.type}`"
            >{{ button.label }}</nuxt-link
          >
        </div>
      </div>
    </div>
  </section>

  <div
    v-else-if="sidebarComponents.length > 0"
    class="tw-px-6 tw-py-8"
    :style="`background-color: var(--c-${vars.backgroundColor || 'section_secondary'})`"
  >
    <div class="tw-w-fulltw-items-center tw-flex tw-flex-col">
      <LibraryPopupsVideoPopup v-if="vars.photoImage" :videoUrl="vars.videoUrl" v-model:is-visible="isVisible" />
      <div
        :class="`${vars.isReversed && 'tw-order-3'} tw-relative tw-col-span-full lg:tw-col-span-6 xl:tw-col-span-5`"
        @click="isVisible = true"
      >
        <LibraryImage v-if="vars.photoImage" :src="vars.photoImage" :h-ratio="430" :w-ratio="645" />

        <library-video-embed
          v-if="!vars.photoImage && vars.videoUrl"
          :video-url="vars.videoUrl"
          title="Video Popup"
          :library-iframe="true"
          :is-popup="vars.isPopupBool"
          :h-ratio="430"
          :w-ratio="645"
        />

        <div
          v-if="vars.videoUrl && vars.isPopupBool"
          class="tw-absolute tw-left-1/2 tw-top-1/2 -tw-translate-x-1/2 -tw-translate-y-1/2"
        >
          <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M32 4C26.4621 4 21.0486 5.64217 16.444 8.71885C11.8395 11.7955 8.25064 16.1685 6.13139 21.2849C4.01213 26.4012 3.45764 32.0311 4.53802 37.4625C5.61841 42.894 8.28515 47.8831 12.201 51.799C16.1169 55.7149 21.106 58.3816 26.5375 59.462C31.969 60.5424 37.5988 59.9879 42.7151 57.8686C47.8315 55.7494 52.2045 52.1605 55.2812 47.556C58.3578 42.9514 60 37.5379 60 32C60 24.5739 57.05 17.452 51.799 12.201C46.548 6.94999 39.4261 4 32 4ZM46.894 33.79L22.894 45.79C22.589 45.9424 22.2501 46.0143 21.9095 45.9989C21.5689 45.9835 21.2379 45.8812 20.948 45.7019C20.658 45.5225 20.4187 45.272 20.2528 44.9741C20.0869 44.6763 19.9999 44.341 20 44V20C20.0002 19.6592 20.0875 19.3241 20.2535 19.0265C20.4196 18.729 20.6589 18.4787 20.9488 18.2996C21.2387 18.1205 21.5696 18.0184 21.91 18.0031C22.2505 17.9878 22.5892 18.0597 22.894 18.212L46.894 30.212C47.2258 30.3783 47.5047 30.6336 47.6997 30.9494C47.8946 31.2651 47.9979 31.6289 47.9979 32C47.9979 32.3711 47.8946 32.7349 47.6997 33.0507C47.5047 33.3664 47.2258 33.6217 46.894 33.788"
              fill="white"
            />
          </svg>
        </div>
      </div>
      <div :class="`${vars.isReversed && 'tw-order-2'} tw-col-span-1`"></div>
      <div class="tw-col-span-full lg:tw-col-span-5">
        <div v-html="vars.titleHtml" class="tw-flex tw-flex-wrap tw-gap-2"></div>
        <div class="tw-space-y-2">
          <p
            v-for="(description, index) in descriptions"
            :key="`contentbox-description-${index}`"
            style="color: var(--c-text_black)"
          >
            {{ description.description }}
          </p>
        </div>
        <div class="tw-mt-8 tw-flex tw-flex-wrap tw-gap-2">
          <nuxt-link
            v-for="(button, index) in buttons"
            :key="`contentbox-button-${index}`"
            :to="button.link"
            :class="`n-${button.type}`"
            >{{ button.label }}</nuxt-link
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { ComponentMixin } from '~/mixins/component.mixin';
import { mapState } from 'pinia';
import { useWebsiteStore } from '~/units/website/store';

export default defineNuxtComponent({
  name: 'ContentBox',

  mixins: [ComponentMixin],

  data() {
    return {
      isVisible: false,
    };
  },

  computed: {
    ...mapState(useWebsiteStore, ['sidebarComponents']),
    descriptions() {
      return this.groupedVariables.descriptions;
    },
    buttons() {
      return this.groupedVariables.buttons;
    },
  },
});
</script>
